// extracted by mini-css-extract-plugin
export var backToBlog = "blogPost-module--backToBlog--PA+wk";
export var blogCats = "blogPost-module--blogCats--ci0Ut";
export var blogContent = "blogPost-module--blogContent--2bLPY";
export var blogContentAuthor = "blogPost-module--blogContentAuthor--iH-l9";
export var blogContentMeta = "blogPost-module--blogContentMeta--myliv";
export var blogContentMetaInner = "blogPost-module--blogContentMetaInner--Mpldm";
export var blogContentText = "blogPost-module--blogContentText--MDWia";
export var blogContentTitle = "blogPost-module--blogContentTitle--lnmmU";
export var blogContentWrap = "blogPost-module--blogContentWrap--hUADr";
export var blogDate = "blogPost-module--blogDate--4N33O";
export var blogRelated = "blogPost-module--blogRelated--6a05g";